import Link from 'next/link';
import { sendGTMEvent } from '@next/third-parties/google';
import classNames from 'classnames';
import { tabsEventKey } from 'components/contentstack/ProductOverviewPage/Brow/types/ETabKeys';
import RatingStars from 'components/nikon-ui/atoms/RatingStars';
import { USER_CATEGORY } from 'helpers/constants/seoConstants';
import { useLocale } from 'helpers/hooks/useLocale';
import { ImageHelpers } from 'helpers/imageHelpers';
import { useAccount } from 'frontastic';
import ProductDescription from './components/ProductDescription';
import { IProductTileProps } from './interfaces';
import styles from './ProductTile.module.scss';
import useProductListItem from '../hooks/useProductListItem';

const ProductTile = ({ product, getProductGTMEventData, index }: IProductTileProps) => {
  const { setVariantById, imageUnavailable, selectedVariant, swatchImages, isSale, isNew, lowerPrice } =
    useProductListItem(product);
  const { account } = useAccount();
  const { locale, isUsaStore } = useLocale();
  const showRating = isUsaStore && !product.isArchived && product.variants[0]?.attributes.isReviewable;

  const handleOnClickProduct = () => {
    sendGTMEvent({
      event: 'select_item',
      hit_time_stamp: Date.now(),
      userId: account?.accountId,
      userCategory: account?.accountId ? USER_CATEGORY.LOGGED_IN : USER_CATEGORY.GUEST,
      ecommerce: {
        actionField: { list: 'Product list', view: 'grid' },
        items: getProductGTMEventData
          ? [getProductGTMEventData(product, index)]
          : [
              {
                item_id: product.key,
                item_name: product.name,
                item_category: product.categories?.map((category) => category.name).join(', '),
                item_variant: selectedVariant?.attributes?.title || selectedVariant.sku || '',
                index,
                item_price: (selectedVariant?.price?.centAmount || 0) / 100,
              },
            ],
      },
    });
  };

  return (
    <div className={styles['container']}>
      <Link href={`${product._url}/overview` || '#'}>
        <a onClick={handleOnClickProduct}>
          <div className={styles['image-container']}>
            <div>
              <img
                alt={product.name}
                src={
                  ImageHelpers.modifiedCTImageUrl(
                    selectedVariant?.representativeImage || selectedVariant.images[0],
                    'medium',
                  ) || imageUnavailable
                }
                onError={({ currentTarget }) => {
                  currentTarget.src = imageUnavailable;
                }}
                loading="lazy"
              />
            </div>
            <div className={styles['variant-swatches']}>
              {swatchImages.map((item, key) => (
                <div
                  key={key}
                  className={classNames({ [styles['selected']]: item.id === selectedVariant.id })}
                  onClick={(e) => {
                    e.preventDefault();
                    setVariantById(item.id);
                  }}
                >
                  <img alt={`variant ${key + 1}`} src={item.image} loading="lazy" />
                </div>
              ))}
            </div>
          </div>
          <div className={styles['separator']}>
            <hr />
          </div>
          <ProductDescription isSale={isSale} isNew={isNew} product={product} lowerPrice={lowerPrice} />
        </a>
      </Link>
      {showRating && (
        <a className={styles['rating']} href={`${product._url}/overview#${tabsEventKey.RATING_AND_REVIEW}`}>
          <RatingStars product={product} variant={product.variants[0]} locale={locale} />
        </a>
      )}
    </div>
  );
};

export default ProductTile;
